import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { configure } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import Toast from "vue-toastification";
import { auth0 } from "@/services/auth0-client";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import LoadingModal from "@/components/loading-modal.vue";

const APP_ENVIRONMENT: string | undefined = process.env.VUE_APP_MODE;
const APP_INSIGHTS: string | undefined = process.env.VUE_APP_APP_INSIGHTS;
// Define the environments that will send exception telemetry to AppInsights
const appInsightsEnv = ["'DEV';", "'QA';", "'UAT';", "'PROD';"];

// polNbr value will be stored in the browser's sessionStorage when it's updated,
//		it's also initialized from sessionStorage when the application is loaded.
// This way, the polNbr value will be stored in the sessionStorage, which retains data for the duration of the browser session.
// However, when the user launches a new window or closes the browser, the data stored in sessionStorage will be cleared,
// 		effectively resetting the polNbr value for the new window.
store.dispatch("initPolNbr");

// Add configuration for update validation triggered on input event
configure({
	validateOnInput: true,
});

// Initialize AppInsights
const appInsights = new ApplicationInsights({
	config: { connectionString: APP_INSIGHTS },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// Define a global error handling function
const handleGlobalError = (error: Error | PromiseRejectionEvent | unknown) => {
	let appInsightsException = new Error();
	let pushErrorPage = true;

	// Check for a PromiseRejectionEvent
	if (error instanceof PromiseRejectionEvent) {
		// Check for an "Invalid state" error when the user is not authenticated
		if (error.reason.error_description === "Invalid state") {
			// Redirect the user to the AuthCallback page
			pushErrorPage = false;
			router.push({ name: "AuthCallback" });
		} else if (error.reason.error_description === "Login required") {
			// Redirect the user to the Login page
			pushErrorPage = false;
			auth0.loginWithRedirect();
		} else {
			// Create an Error object from the error reason
			appInsightsException = new Error(error.reason);
		}
	} else {
		if (error instanceof Error) {
			// Create an Error object from the error
			appInsightsException = error;
		} else {
			// Create an Error object from the error stringified
			appInsightsException = new Error(JSON.stringify(error));
		}
	}

	// Send the exception telemetry to AppInsights if the environment is DEV, QA, UAT, or PROD
	if (APP_ENVIRONMENT && appInsightsEnv.includes(APP_ENVIRONMENT)) {
		appInsights.trackException({ exception: appInsightsException });
	} else {
		// Log the error to the console if the environment is not DEV, QA, UAT, or PROD
		console.error(error);
	}

	// Redirect the user to the Error page
	if (pushErrorPage) {
		router.push({ name: "ErrorPage" });
	}
};

// Set the global error handler for routing
router.onError((error) => {
	handleGlobalError(error);
});

// Create the Vue application
const app = createApp(App).component("VueDatePicker", VueDatePicker).component("LoadingModal", LoadingModal).use(store).use(router).use(Toast).use(auth0);

// Set the global error handler for the application
app.config.errorHandler = (error) => {
	handleGlobalError(error);
};

// Listen for promise rejections
window.addEventListener("unhandledrejection", (event) => {
	handleGlobalError(event);
});

app.mount("#app");
