import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withKeys as _withKeys, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header border-0" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "modal-footer justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: "modal-backdrop fade show",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('handleNo'))),
      role: "presentation"
    }),
    _createElementVNode("div", {
      class: "modal d-block",
      tabindex: "-1",
      ref: "modalElementRef",
      onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => ($setup.emit('handleNo')), ["esc"])),
      role: "dialog"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              ref: "closeButtonRef",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('handleNo')))
            }, null, 512 /* NEED_PATCH */)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "modalContent", {}, () => [
              _createTextVNode("Modal Content")
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-outline-primary px-4",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.emit('handleNo')))
            }, " No "),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary px-4",
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.emit('handleYes')))
            }, " Yes ")
          ])
        ])
      ])
    ], 544 /* HYDRATE_EVENTS, NEED_PATCH */)
  ]))
}